import axios from '@axios'

axios.defaults.timeout = 0

export default {
  namespaced: true,
  state: {
  },
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/product/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSelect(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/products')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/product/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateProduct(ctx, { product, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/product/addupdate', { obj: product, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeleteProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/product/delete', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPackages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/package/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPackageSelect(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/packages')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPackage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/package/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    DeletePackage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/package/delete', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdatePackage(ctx, { pack, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/package/addupdate', { obj: pack, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveFactor(ctx, { PackageId, FactorList, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/factors/addupdate', { obj: FactorList, packid: PackageId, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDealerFactor(ctx, {
      PackageId, FactorList, agencyId, userData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/dealer-factors/delete', {
            obj: FactorList, packid: PackageId, user: userData, agencyId,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveDealerFactor(ctx, {
      PackageId, FactorList, agencyId, userData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/dealer-factors/addupdate', {
            obj: FactorList, packid: PackageId, user: userData, agencyId,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/product/countries')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fecthPackageFactor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/package/factors', { Id: id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fecthDealerPackageFactor(ctx, { agencyId, packageId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/${agencyId}/custom-factor/package/${packageId}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fecthProductsByProgram(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/program/${id}/products`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
